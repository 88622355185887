html, body, #root, .App {
  height: 100%;
  scroll-behavior: smooth;
}

.LandingPage {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-top: 5%;
}

.landing {
  width: 100%;
}